import React from 'react';

import StarIcon from '../../assets/images/technical-support.png'

import icon1 from '../../assets/images/icons/005-leader.png'
import icon2 from '../../assets/images/icons/006-decision-making.png'
import icon3 from '../../assets/images/icons/003-goal.png'
import icon4 from '../../assets/images/icons/008-leadership.png'
import icon5 from '../../assets/images/icons/009-technical-support.png'
import icon6 from '../../assets/images/icons/010-experience.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={StarIcon} alt="image" /> 
                        Solutions
                    </span>
                    <h2>Experience</h2>
                    <p></p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={icon1} alt="banner" />
                            </div>
                            <h3>
                                Achieve through people as a people-first leader
                            </h3>
                            <p>Passionate about technology and its potential to deliver products that users are excited to use</p>
                            <p>Building great teams to deliver meaningful features to users quickly and with confidence</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={icon2} alt="banner" />
                            </div>

                            <h3>
                            Make winning decisions with insightful thinking and direction
                            </h3>

                            <p>Highly self-motivated with the ability to quickly analyse and solve complex problems</p>
                            <p>Cooperative, responsible and flexible rapidly adapting to changing the landscape</p>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={icon3} alt="banner" />
                            </div>

                            <h3>
                            Deliver results through ownership, drive and resilience
                            </h3> 

                            <p>Proactive and highly organised, with excellent time management and planning skills</p>
                            <p>Successful history of career progression starting as a system developer and now with senior positions
                            growing responsibilities and ownership</p>
                            
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={icon4} alt="banner" />
                            </div>
                            <h3>
                            Leads with passion constantly communicating and empowering teams to operate independently
                            </h3>
                            <p>Excellent communication and presentation skills with the ability to influence across the
                            organisation</p>
                            <p>Confident while being diplomatic and tactful, able to communicate effectively to business
                            and other stakeholders and all levels</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={icon5} alt="banner" />
                            </div>

                            <h3>
                            Technical knowledge to design and deliver innovative customer experiences
                            </h3>

                            <p>Extensive hands-on experience in object-oriented programming language Java</p>
                            <p>Experience performing technical analysis on different technology design and implementation options</p>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                            <img src={icon6} alt="banner" />
                            </div>

                            <h3>
                            Practical experience spanning 20+ years of delivering large global platforms
                            </h3> 

                            <p>Experience leading cross-functional teams of +20 professionals</p>
                            <p>Record of developing and delivering large scale, complex scale platforms</p>
                            
                        </div>
                    </div>
                </div>

                
            </div>
        </section>
    )
}

export default OurSolutions;