import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import StarIcon from "../../assets/images/technical-support.png"

const Credentials = () => {
  return (
    <section className="solutions-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={StarIcon} alt="image" />
            Credentials
          </span>
          <h2>Certificates</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage
                  src="../../assets/images/logos/badges/aws.png"
                  width={150}
                  placeholder="blurred"
                  alt="AWS"
                />
              </div>

              <h3>AWS Certified Solutions Architect</h3>
              <h6>Cloud Solutions</h6>
              <div className="courses-details-info">
                <ul className="info">
                  <li>Compute</li>
                  <li>Storage</li>
                  <li>Database</li>
                  <li>Networking & Content Delivery</li>
                  <li>Analytics</li>
                  <li>Machine Learning</li>
                  <li>Security, Identity, and Compliance</li>
                </ul>
                <div className="btn-box">
                  <Link
                    className="default-btn"
                    to="https://www.youracclaim.com/badges/8c30e5b6-2c3b-4330-9a7b-ff8f97bfade4"
                    target="_blank"
                  >
                    <i className="flaticon-right"></i>See credential{" "}
                    <span></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage
                  src="../../assets/images/logos/badges/pmp.png"
                  width={150}
                  placeholder="blurred"
                  alt="PMP"
                />
              </div>

              <h3>PMI® / PMP Certified Professional</h3>
              <h6>Program Managament</h6>
              <div className="courses-details-info">
                <ul className="info">
                  <li>Risk Management</li>
                  <li>Budget & Cost Tracking</li>
                  <li>Communication Plan</li>
                  <li>Scope Management</li>
                  <li>Team Leadership</li>
                  <li>Quality Control</li>
                  <li>Vendor Management</li>
                </ul>
                <div className="btn-box">
                  <Link
                    className="default-btn"
                    href="https://www.youracclaim.com/badges/11f701f4-581f-4a97-a15e-92f86b7be1b3"
                    target="_blank"
                  >
                    <i className="flaticon-right"></i>See credential{" "}
                    <span></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
            <div className="single-solutions-box">
              <div className="icon">
                <StaticImage
                  src="../../assets/images/logos/badges/icp.png"
                  width={150}
                  placeholder="blurred"
                  alt="ICP"
                />
              </div>

              <h3>ICAgile Certified Professional</h3>
              <h6>Agile Transformations</h6>
              <div className="courses-details-info">
                <ul className="info">
                  <li>Agile Mindset</li>
                  <li>Enterprise & Business Agility</li>
                  <li>Organization Sstructures & Processes</li>
                  <li>Leadership & Culture</li>
                  <li>Change & Agile Transitions</li>
                  <li>Value Driven Development</li>
                  <li>Planning and Adapting</li>
                </ul>
                <div className="btn-box">
                  <Link
                    className="default-btn"
                    href="http://icagile.com/Agile-Professionals/Find-Agile-Professionals/ctl/UserDetails/mid/938/uid/026ad34e-44b3-4d67-9c35-643cc1572659"
                    target="_blank"
                  >
                    <i className="flaticon-right"></i>See credential{" "}
                    <span></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Credentials
