import React from 'react'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/technical-support.png'
 
const AboutMeContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    About Me
                                </span>
                                <h2>Head of Professional Services</h2>
                                <p>Passionate and highly skilled leader with a proven track record, mentoring and managing teams to deliver cutting-edge technology solutions in EMEA and LATAM.</p>
                                
                                <ul className="features-list">
                                    <li>
                                        <h3>+22 Years</h3>
                                        <p>Experience in Technology</p>
                                    </li>
                                    <li>
                                        <h3>+5M USD</h3>
                                        <p>Yearly in Services Bookings</p>
                                    </li>
                                    <li>
                                        <h3>+11 Years</h3>
                                        <p>Working in Professioal Services</p>
                                    </li>
                                    <li>
                                        <h3>+20 People</h3>
                                        <p>Under Direct Management</p>
                                    </li>
                                </ul>
                                <p>
                                I've had the opportunity to combine hands-on software engineering management and professional services expertise, building cohesive, high-caliber Services Delivery practices.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutMeContent;