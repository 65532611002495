import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import AboutMeContent from '../components/About/AboutMeContent'
import Technologies from "../components/Common/Technologies"
import OurSolutions from "../components/About/OurSolutions"
import Credentials from '../components/About/Credentials'
import Diversity from '../components/About/Diversity'
 
const About = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="About" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="About" 
            />
            <AboutMeContent />
            <OurSolutions />
            <Credentials />
            <Technologies />
            <Diversity />
            <Footer />
        </Layout>
    );
}

export default About;