import React from "react"
import { Link } from "gatsby"
import course1 from "../../assets/images/diversity/solve-problems.jpg"
import course2 from "../../assets/images/diversity/products.jpg"
import course3 from "../../assets/images/diversity/solutions.jpg"
import course4 from "../../assets/images/diversity/connection.jpg"
import course5 from "../../assets/images/diversity/diversity1.jpg"
import shape from "../../assets/images/shape/vector-shape6.png"

const Diversity = () => {
  return (
    <div className="courses-area ptb-100 bg-fafafb">
      <div className="container">
        <div className="section-title">
          <h2>Embrace Diversity and Inclusion</h2>
          <p>
            Workplace diversity is the understanding, acceptance, and value that
            is placed on the differences between coworkers. Inclusion is the
            environment that you create in order to collaborate, support, and
            respect these differences.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <img src={course1} alt="about" />
              </div>
              <div className="courses-content">
                <h3>Solve Problems Faster</h3>
                <p>
                  Diversity encourages the search for novel information and
                  perspectives, leading to better decision making and problem
                  solving.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="single-courses-box ">
              <div className="courses-image">
                <img src={course2} alt="about" />
              </div>
              <div className="courses-content">
                <h3>Builds better products</h3>
                <p>
                  The more perspectives we can bring to the products we build,
                  then the more valuable those products will be for more people.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <img src={course3} alt="about" />
              </div>
              <div className="courses-content">
                <h3>The Right Solutions</h3>
                <p>
                  People with different backgrounds bring unique information and
                  experiences to tasks. Interacting with individuals who are
                  different forces group members to prepare better, anticipate
                  alternative viewpoints, and expect that reaching consensus
                  will take effort.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="single-courses-box">
              <div className="courses-image">
                <img src={course4} alt="about" />
              </div>
              <div className="courses-content">
                <h3>Connect With Customers</h3>
                <p>
                  The audience for our software is widely diverse, so why
                  wouldn’t we want our team to reflect that level of diversity
                  as well? Teams that are too homogeneous have a challenge in
                  tapping into empathy for customers and being able to reach a
                  wider market.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-6" >
            <div className="single-courses-box">
              <div className="courses-image">
                  <img src={course5} alt="about" />
                
              </div>
              <div className="courses-content">
                <h3>
                  <a
                    href="https://www.linkedin.com/pulse/diversity-starts-you-marcos-lomboglia/"
                    target="_blank" rel="noreferrer"
                  >
                    Diversity Starts with YOU
                  </a>
                </h3>
                <p>
                  <a
                    href="https://www.linkedin.com/pulse/diversity-starts-you-marcos-lomboglia/"
                    target="_blank" rel="noreferrer"
                  >
                    Read my article published on LinkedIn sharing some of my
                    experiences on creating diverse and inclusive teams. I have
                    had the pleasure of hiring, mentoring and leading
                    distributed teams with members from 5 continents with
                    different work styles, expanding their knowledge,
                    challenging themselves, growing and building a better
                    product for everyone.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="vector-shape6">
        <img src={shape} alt="about" />
      </div>
    </div>
  )
}

export default Diversity
